body {
    /* background-color: #fcb6b3; */
    /* couleur de fond */
    
    background-color: #FADBDB; 
    /* couleur de fond de texte*/

    
  }

footer {
  background-color: #fcb6b3;
  /* couleur de fond
  
  /* background-color: #FADBDB; 
  couleur de fond de texte */
}

/* body {
  padding-top: 70px; /* Ajuste selon la hauteur de la Navbar *
} */
  
/* 
.img-responsive {
  max-width: 100%;
  height: auto;
} */
.news-content img,
.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}